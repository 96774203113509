var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    class: {
      headerTop: _vm.headerTop,
      headerTopSm: _vm.headerTopSm,
      headerBottom: _vm.headerBottom,
      headerLogoNone: _vm.headerLogoNone,
      menuTop: _vm.menuTop,
      menuBottom: _vm.menuBottom,
      pageArchive: _vm.pageArchive,
      pageContact: _vm.pageContact
    },
    attrs: {
      "id": "body"
    }
  }, [_c('div', {
    staticClass: "d-md-none",
    class: {
      introHidden: _vm.introHidden
    },
    style: {
      visibility: _vm.introVisibility,
      opacity: _vm.introOpacity,
      backgroundImage: 'url(' + _vm.setting.site.introMobile + ')'
    },
    attrs: {
      "id": "intro"
    },
    on: {
      "click": function ($event) {
        _vm.showIntro = false;
      }
    }
  }), _c('div', {
    staticClass: "d-none d-md-block",
    class: {
      introHidden: _vm.introHidden
    },
    style: {
      visibility: _vm.introVisibility,
      opacity: _vm.introOpacity,
      backgroundImage: 'url(' + _vm.setting.site.introImage + ')'
    },
    attrs: {
      "id": "intro"
    },
    on: {
      "click": function ($event) {
        _vm.showIntro = false;
      }
    }
  }), _c('div', {
    attrs: {
      "id": "main"
    }
  }, [_c('main-header', {
    on: {
      "intro": function ($event) {
        _vm.showIntro = true;
      }
    }
  }), _c('div', {
    attrs: {
      "id": "container"
    }
  }, [_c('transition', {
    attrs: {
      "name": "slide-fade",
      "mode": "out-in"
    }
  }, [_c('router-view', {
    key: _vm.$i18n.locale
  })], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }