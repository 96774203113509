<template>
    <v-app>
        <!-- vue transition 애니메이션 여기 참고 -->
        <!-- https://kr.vuejs.org/v2/guide/transitioning-state.html -->
        <!-- * 참고) a 태그로 이동하면 안됨, router-link 태그 사용 할것 -->
        <!-- <transition name="fade" mode="out-in">
            <router-view :key="$i18n.locale"/>
        </transition> -->

        <div id="body" :class="{ headerTop, headerTopSm, headerBottom, headerLogoNone, menuTop, menuBottom, pageArchive, pageContact }">
            <!-- 인트로 -->
            <!-- 모바일 -->
            <div id="intro" class="d-md-none" :style="{ visibility: introVisibility, opacity: introOpacity, backgroundImage : 'url('+setting.site.introMobile+')'}" @click="showIntro = false" :class="{ introHidden }"></div>
            <!-- PC -->
            <div id="intro" class="d-none d-md-block" :style="{ visibility: introVisibility, opacity: introOpacity, backgroundImage : 'url('+setting.site.introImage+')'}" @click="showIntro = false" :class="{ introHidden }"></div>
            <!-- 메인 -->
            <div id="main">
                <!-- 헤더 -->
                <main-header @intro="showIntro = true"></main-header>
                <div id="container">
                    <transition name="slide-fade" mode="out-in">
                        <router-view :key="$i18n.locale"/>
                    </transition>
                </div>
            </div>
        </div>

        <!-- <router-view :key="$i18n.locale"/> -->
        <!-- <animation-card /> -->
    </v-app>
</template>

<script>
// import ClientPage from "@/pages/client/templates/ClientPage.vue";
// import AnimationCard from './components/client/anim/animation-card.vue';
import { Header } from "@/plugins/header.js"; // 스크립트
import MainHeader from "@/components/client/inc/main-header.vue";
import api from "@/api";
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        // MainFooter,
    },
    data(){
        return {
            headerTop: false,
            headerTopSm: false,
            headerBottom: false,
            headerLogoNone: false,
            menuTop: false,
            menuBottom: false,
            pageArchive: false,
            pageContact: false,

            showIntro: true,
            introHidden: false,

            setting : null
        }
    },
    created() {
        this.getSetting();
    },
    mounted() {
        this.headerTop = true;
        this.menuTop = true;

        this.header();

        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
    },
    methods: {
        header: function() {
            this.$nextTick(function() {
                Header(); // Header 스크립트 로드
            });
        },
    },
    computed: {
        async getSetting() {
            try {
                var { setting } = await api.v1.setting.get({});
                this.setting = setting;
            } catch (error) {
                this.handleError(error);
            }
        },
        introVisibility(){
            return this.showIntro ? "inherit" : "hidden";
        },
        introOpacity(){
            return this.showIntro ? 1 : 0;
        }
    },
    // URL 경로가 바뀔때
    watch: {
        $route(to, from){
            // from.path -> to.path
            if(to.path == "/"){
                this.headerTop = true;
                this.menuTop = true;

                this.headerTopSm = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuBottom = false;
                this.pageArchive = false;
                this.pageContact = false;
                this.introHidden = false;
            }
            else if(to.path == "/about"){
                this.headerBottom = true;
                this.menuTop = true;
                this.introHidden = true;

                this.headerTop = false;
                this.headerTopSm = false;
                this.headerLogoNone = false;
                this.menuBottom = false;
                this.pageArchive = false;
                this.pageContact = false;
            }
            else if(to.path == "/archive"){
                this.headerTopSm = true;
                this.menuBottom = true;
                this.introHidden = true;
                this.pageArchive = true;

                this.headerTop = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuTop = false;
                this.pageContact = false;
            }
            else if(to.path.match(/^\/archive\/[a-zA-Z]+$/)){
                this.headerTopSm = true;
                this.menuBottom = true;
                this.introHidden = true;
                this.pageArchive = true;

                this.headerTop = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuTop = false;
                this.pageContact = false;
            }
            else if(to.path.match(/^\/archive\/[a-zA-Z]+\/.+$/)){
                this.headerTopSm = true;
                this.menuBottom = true;
                this.introHidden = true;
                this.pageArchive = true;

                this.headerTop = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuTop = false;
                this.pageContact = false;
            }
            else if(to.path == "/contact"){
                this.headerLogoNone = true;
                this.menuTop = true;
                this.introHidden = true;
                this.pageContact = true;

                this.headerTop = false;
                this.headerTopSm = false;
                this.headerBottom = false;
                this.menuBottom = false;
                this.pageArchive = false;
            }
            else if(to.path == "/board"){
                this.headerTopSm = true;
                this.menuBottom = true;
                this.introHidden = true;

                this.headerTop = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuTop = false;
                this.pageArchive = false;
                this.pageContact = false;
            }
            else if(to.path.match(/^\/board\/.+$/)){
                this.headerTopSm = true;
                this.menuBottom = true;
                this.introHidden = true;

                this.headerTop = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuTop = false;
                this.pageArchive = false;
                this.pageContact = false;
            }
            else{
                this.headerTop = true;
                this.menuTop = true;
                this.introHidden = true;

                this.headerTopSm = false;
                this.headerBottom = false;
                this.headerLogoNone = false;
                this.menuBottom = false;
                this.pageArchive = false;
                this.pageContact = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
// 시작 - down
.slide-fade-leave, .slide-fade-leave-to, .slide-fade-leave-active{
    opacity: 0;
    transform: translateY(100%);
    transition: all .4s cubic-bezier(.77,0,.175,1);

}

// 끝 - up
.slide-fade-enter, .slide-fade-enter-to, .slide-fade-enter-active{
    opacity: 0;
    transform: translateY(100%);
    transition: all .8s cubic-bezier(.77,0,.175,1);
    animation: slide-up .8s cubic-bezier(.77,0,.175,1);
    animation-delay: 1.2s;
}
@keyframes slide-up{
    0%{
        opacity: 0;
        transform: translateY(100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
</style>